.Write {
  .EditorWrapper {
    .md-editor-toolbar {
      background: #333;
    }
    .md-RichEditor-styleButton {
      color: rgba(255, 255, 255, 0.8);
      &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .md-RichEditor-activeButton {
      background: #222;
      color: #fff;
    }
    .md-RichEditor-activeButton:hover {
      color: #fff;
    }
  }
}