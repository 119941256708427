@import '../../../node_modules/antd/lib/style/color/colors.less';

.Landing {
  overflow-x: hidden;

  h2, h3 {
    text-align: center;
    font-size: 1.75rem;
  }
  .Circle {
    height: 8px;
    width: 8px;
    background: @green-6;
    border-radius: 999px;
    margin-right: 0.5rem;
  }
  .Blink {
    animation: blinker 1.25s cubic-bezier(.5, 0, 1, 1) infinite alternate;
  }

  @keyframes blinker {
    from { opacity: 1; }
    to { opacity: 0; }
  }
}