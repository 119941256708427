.TitleText {
  font-family: 'Frank Ruhl Libre', serif;
  font-size: 19px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Frank Ruhl Libre', serif;
  font-weight: 600;
  letter-spacing: 0.025rem;
  margin: 0.5rem 0;
}

h1 {
  line-height: 3.25rem;
}
